import {Injectable} from '@angular/core';
import {
   HttpRequest,
   HttpHandler,
   HttpEvent,
   HttpInterceptor, HttpClient, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';


@Injectable()
export class CsrfRefreshInterceptor implements HttpInterceptor {
   private refreshingCsrf = false;

   constructor(private http: HttpClient) {
   }

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
         catchError((error: HttpErrorResponse) => {
            if (error.status === 419 && !this.refreshingCsrf) {
               this.refreshingCsrf = true;

               // Attempt to refresh CSRF token
               return this.http.get('/v1/csrf-cookie').pipe(
                  switchMap(() => {
                     // Retry the original request
                     this.refreshingCsrf = false;
                     const clonedRequest = req.clone();
                     return next.handle(clonedRequest);
                  }),
                  catchError(err => {
                     this.refreshingCsrf = false;
                     return throwError(() => err);
                  })
               );
            }
            return throwError(() => error);
         })
      );
   }
}
