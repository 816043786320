import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from '../../services/api.service';
import {finalize} from "rxjs/operators";

@Component({
   selector: 'app-shipping-label',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './shipping-label.component.html',
   styleUrls: ['./shipping-label.component.scss']
})
export class ShippingLabelComponent {
   @Input() id;
   public loading = false;

   constructor(public api: ApiService) {

   }

   download() {
      this.loading = true;
      this.api.post('admin/book-order/download-shipping-label', {id: this.id})
         .pipe(finalize(() => this.loading = false))
         .subscribe((res: any) => {
            window.location.href = res.url;
         })
   }
}
