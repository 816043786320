<div class="page-title d-flex justify-content-between align-items-center">
   <h3>Manage Book Orders</h3>
   <div>
      <!--      <button class="btn btn-primary btn-icon" (click)="add(addNew)"> Add New</button>-->
   </div>
</div>
<xs-laravel-data-table #table [endpoint]="endpoint" [config]="tableConfig">
   <ng-template field="label" let-idx="index" lt-cell-template="" let-item="">
      <app-shipping-label [id]="item.id"></app-shipping-label>
   </ng-template>
   <ng-template lt-cell-template field="shipping_status" let-idx="index" let-item="">
      <app-badge [text]="item.shipping_status??'pending'"></app-badge>
   </ng-template>
</xs-laravel-data-table>

<ng-template #addNew let-modal>
   <div class="modal-header">
      <h5 class="modal-title">{{ form.value.id ? 'Update' : 'New' }} BookOrder</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <form [formGroup]="form">
         <div class="form-group">
            <label for="" class="form-label">Courier Service</label>
            <input type="text" formControlName="courier_service" class="form-control">
         </div>
         <div class="form-group">
            <label for="" class="form-label">Tracking Number</label>
            <input type="text" formControlName="courier_service_number" class="form-control">
         </div>
         <div class="form-group">
            <label for="" class="form-label">Tracking Url</label>
            <input type="text" formControlName="tracking_url" class="form-control">
         </div>
         <div class="form-group">
            <label for="" class="form-label">DIspatch date</label>
            <app-date-picker formControlName="dispatch_date"></app-date-picker>
         </div>
         <div class="form-group">
            <label for="" class="form-label">Status</label>
            <select type="text" formControlName="shipping_status" class="form-control">
               <option [value]="'pending'">Pending</option>
               <option [value]="'dispatched'">Dispatched</option>
               <option [value]="'delivered'">Delivered</option>
            </select>
         </div>
      </form>
   </div>
   <div class="modal-footer">
      <button (click)="save()" class="btn btn-primary">{{ form.value.id ? 'Update' : 'Save' }}</button>
   </div>
</ng-template>

<ng-template let-modal #orderDetail>
   <div class="modal-header">
      <h5 class="modal-title"> Order Detail</h5>
      <button type="button" class="close" (click)="modal.close(false)" aria-label="Close">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <app-order-detail [order]="selectedOrder"></app-order-detail>
   </div>

</ng-template>
